<template>
  <v-expansion-panels
    v-model="activeItem"
    class="expansion-panel"
    tile accordion>
    <v-expansion-panel
      v-for="(item, i) in faQuestionList"
      :key="i"
    >
      <v-expansion-panel-header
        :class="{ 'sub-title': i === activeItem }"
        hide-actions
        class="question__content"
      >
        <div class="expand-header">
          <span class="question__content_text">{{getPersonalityTest.name}}'s Result
            <span class="count-respondents">
              {{getPersonalityTest.othersAmount}}
            </span>
          </span>
          <img :src="minusIcon" alt="icon-minus" class="icon" v-if="i === activeItem"/>
          <img :src="plusIcon" alt="icon-plus" class="icon" v-else/>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content
        class="question__answer"
      >
        <NewBarChart
          :strength-conversion-score-object="getPersonalityTest.strengthConversion"
        ></NewBarChart>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import NewBarChart from '@components/Charts/NewBarChart.vue';
import minusIcon from '@assets/minus-icon.svg';
import plusIcon from '@assets/plus-icon.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'NewBarChartForCrowd',
  components: { NewBarChart },
  computed: {
    ...mapGetters({
      getPersonalityTest: 'invitation/getPersonalityTest',
      getProfile: 'auth/getProfile',
    }),
  },
  data: () => ({
    minusIcon,
    plusIcon,
    activeItem: [],
    faQuestionList: ['firstRecord'],
  }),
};
</script>

<style lang="scss" scoped>
  .expand-header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
  }
  .question__answer {
    background-color: #F3F4F6;
  }

  .v-expansion-panel-header {
    border-top: 1px solid #eaebf1;
    border-bottom: 1px solid #eaebf1;
    background-color: #F3F4F6;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .expansion-panel {
    margin-top: 26px;
    position: static;
  }
  .count-respondents {
    background-color: #FF5151;
    border-radius: 50%;
    padding: 5px 10px;
    font-family: $newDefaultFont;
    font-weight: bold;
    font-size: 14px;
    align-items: center;
    color: #FFFFFF;
    margin-left: 15px;
  }
  .expand-header {
    font-weight: bold;
    font-size: 20px;
    color: #320959;
  }
</style>
