<template>
  <div>
    <div class="title-completed-crowd-survey">
      Thank you so much!
    </div>
    <div class="subtitle-completed-crowd-survey">
      Completing this assessment helps your contacts understand their Strengths in an unique way.
    </div>
    <NewBarChartForCrowd/>
  </div>
</template>

<script>
import NewBarChartForCrowd from '@components/Charts/NewBarChartForCrowd.vue';
import { mapGetters } from 'vuex';
// import api from '@api/index';

export default {
  name: 'CompletedCrowdSurvey',
  components: { NewBarChartForCrowd },
  computed: {
    ...mapGetters({
      getPersonalityTest: 'invitation/getPersonalityTest',
      getProfile: 'auth/getProfile',
    }),
  },
  props: {
    userId: {
      type: String,
    },
  },
  created() {
    // api.questionnaire.getInvitationAnswer('61ee7545aed90374e0447e62')
    //   .then((answer) => {
    //     console.log('result', answer);
    //   });
    // console.log('this.getPersonalityTest', this.getPersonalityTest);
  },
};
</script>

<style lang="scss" scoped>
  .title-completed-crowd-survey {
    font-family: $newDefaultFont;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    color: #320959;
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .subtitle-completed-crowd-survey {
    font-family: $newDefaultFont;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
</style>
