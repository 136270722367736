<template>
  <Content class="introduction">
    <div>
      <div class="title-introduction">How it Works</div>
      <div class="introduction-cards">
        <IntroductionCard text="Take a personality self-<br>assessment"
                          :fontSize="18"
                          image-name="takeAssessment.svg"/>
        <IntroductionCard text="Ask your contacts to anonymously<br>answer questions about you."
                          :font-size="18"
                          image-name="shareAssessmentRight.svg"/>
        <IntroductionCard text="Compare how you see your Strengths<br>with
         how your contacts see them"
                          :fontSize="20"
                          image-name="shareAssessment.svg"/>
      </div>
    </div>
  </Content>
</template>

<script>
import IntroductionCard from '@components/Onboarding/IntroductionCard.vue';

export default {
  name: 'Introduction',
  components: {
    IntroductionCard,
  },
};
</script>

<style lang="scss" scoped>
  .introduction {
    margin-top: 32px;
    background: #FFFFFF;
    border: 1px solid #F3F4F6;
    box-sizing: border-box;
    border-radius: 30px 30px 0 0;
  }
  .title-introduction {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 23px;
    color: $txtColorNewDesignMain;
    font-family: $newDefaultFont;
    margin-top: 57px;
  }
  .introduction-cards > .introduction-card:first-child {
    margin: 0;
  }
</style>
