<template>
  <Content class="take-assessment">
    <div class="title">Who are you?</div>
    <div class="text">Find out what your true strengths are with the
      <span>INNERWORKS PERSONALITY ASSESSMENT</span></div>
    <div class="take-assessment-button">
      <button class="assessment-redirect-button" @click="redirectToPersonalityAssessment"
      >Take The Assessment</button>
    </div>
  </Content>
</template>

<script>
import userBehave from '@constants/userBehave';

export default {
  name: 'TakePersonalityAssessment',
  methods: {
    redirectToPersonalityAssessment() {
      this.$api.userBehaveSite.saveSiteEvent(userBehave.takeTheAssessment);
      this.$store.dispatch('showComponent/startedPage');
      this.$router.push({
        name: 'questionnaire',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .take-assessment {
    font-family: $newDefaultFont;
    background: #F3F4F6;
    margin-top: 50px;
  }
  .title {
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    color: $txtColorNewDesignMain;
  }
  .text {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #222222;
    padding: 10px 14px;
    span {
      line-height: 14px;
      font-weight: bold;
      font-size: 14px;
    }
  }
  .take-assessment-button {
    line-height: 20px;
    display: flex;
    justify-content: center;
    margin-top: 21px;
    .assessment-redirect-button {
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
      box-sizing: content-box;
      border-radius: 5px;
      padding: 8px 16px;
      width: 190px;
      background: $txtColor7;
      border: 1px solid $txtColor7;
    }
  }
</style>
