<template>
  <div class="onBoarding">
    <Content>
      <Header
        class="header-innerworks"
      />
      <CompletedCrowdSurvey v-if="getPersonalityTest.name"/>
      <TakePersonalityAssessment/>
      <Introduction/>
      <FrequentlyAskedQuestions/>
    </Content>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TakePersonalityAssessment from '@components/Onboarding/TakePersonalityAssessment.vue';
import FrequentlyAskedQuestions from '@components/FrequentlyAskedQuestions/FAQOnboarding.vue';
import Introduction from '@components/Onboarding/Introduction.vue';
import Header from '@components/Header/Header.vue';
import CompletedCrowdSurvey from '@components/Onboarding/CompletedCrowdSurvey.vue';
import userBehave from '@constants/userBehave';

export default {
  components: {
    CompletedCrowdSurvey,
    Introduction,
    TakePersonalityAssessment,
    Header,
    FrequentlyAskedQuestions,
  },
  props: {
    userId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
      getPersonalityTest: 'invitation/getPersonalityTest',
    }),
  },
  mounted() {
    this.$api.userBehaveSite.saveSiteEvent(userBehave.mainPage);
    // eslint-disable-next-line camelcase,no-use-before-define
    const Tawk_API = Tawk_API || {};
    // eslint-disable-next-line camelcase,no-unused-vars
    const Tawk_LoadStart = new Date();
    (function () {
      const s1 = document.createElement('script');
      const s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/5f9ad3827f0a8e57c2d83734/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    }());
  },
  name: 'Main',
};
</script>

<style lang="scss">
</style>
