<template>
  <div class="introduction-card" :style="{fontSize: `${fontSize}px`}">
    <figure>
      <img :src="pathToImage" alt="introductionImage"/>
      <figcaption class="image-caption"><span v-html="text">{{text}}</span></figcaption>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'IntroductionCard',
  props: {
    imageName: {
      type: String,
    },
    text: {
      type: String,
    },
    fontSize: {
      type: Number,
    },
  },

  data() {
    return {
      pathToImage: '',
    };
  },
  created() {
    const images = require.context('@assets/', false, /\.svg$/);
    this.pathToImage = images(`./${this.imageName}`);
  },
};
</script>

<style lang="scss" scoped>
  .introduction-card {
    text-align: center;
    font-family: $newDefaultFont;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    margin-top: 41px;
  }
  .image-caption {
    padding-top: 18px;
  }


</style>
